'use client'

import Card from '@lyra/core/components/Card'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import Heading from '@lyra/core/components/Heading'
import PageWithBanner from '@lyra/web/components/layout/PageWithBanner'
import TabsHorizontal from '@lyra/web/components/layout/TabsHorizontal'
import AirdropHeaderPoints from '@lyra/web/containers/airdrop/AirdropHeaderPoints'
import AirdropLevelProgressRow from '@lyra/web/containers/airdrop/AirdropLevelProgressRow'
import LevelsTooltipContent from '@lyra/web/containers/common/LevelsTooltipContent'
import useAuth from '@lyra/web/hooks/useAuth'
import usePoints from '@lyra/web/hooks/usePoints'
import useSelectedTabPage from '@lyra/web/hooks/useSelectedTabPage'
import useTabs from '@lyra/web/hooks/useTabs'
import { formatLevel } from '@lyra/web/utils/points'
import { TabId } from '@lyra/web/utils/tabs'
import { useMemo } from 'react'

type Props = {
  children: React.ReactNode
}

const AirdropLayoutHelper = ({ children }: Props) => {
  const { level, epoch } = usePoints()
  const { isAuthenticated } = useAuth()

  const tab = useTabs()[TabId.Airdrop]
  const selectedPage = useSelectedTabPage()

  const pages = useMemo(() => {
    return tab.pages.map((page) => ({ ...page, isSelected: selectedPage?.id === page.id }))
  }, [tab.pages, selectedPage])

  return (
    <PageWithBanner
      bannerTitle={
        isAuthenticated ? (
          <AirdropHeaderPoints />
        ) : (
          <Heading size="h1" color="cta">
            Airdrop
          </Heading>
        )
      }
      bannerSubtitle="Deposit, trade and refer friends to earn DRV, OP and USDC"
      bannerDesktopRightContent={
        isAuthenticated ? (
          <Card backgroundColor="$appBg">
            <DataWithLabel
              label="Level"
              tooltipContent={level ? <LevelsTooltipContent level={level} epoch={epoch} /> : null}
              value={level ? formatLevel(level) : 'Unknown'}
            />
          </Card>
        ) : null
      }
      bannerBottomRow={isAuthenticated ? <AirdropLevelProgressRow /> : null}
      bannerTabs={
        <TabsHorizontal
          marginHorizontal="$1"
          size="lg"
          pages={pages}
          style={{ overflowX: 'auto' }}
        />
      }
    >
      {children}
    </PageWithBanner>
  )
}

export default AirdropLayoutHelper
