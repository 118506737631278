'use client'

import BodyText from '@lyra/core/components/BodyText'
import Heading from '@lyra/core/components/Heading'
import React from 'react'
import { XStack, YStack } from 'tamagui'

type Props = {
  pageWidth?: number
  bannerTitle: React.ReactNode
  bannerSubtitle?: React.ReactNode
  bannerDesktopRightContent?: React.ReactNode
  bannerBackgroundImage?: React.ReactNode
  bannerTopRow?: React.ReactNode
  bannerBottomRow?: React.ReactNode
  bannerBackgroundColor?: string
  bannerTabs?: React.ReactNode
  children?: React.ReactNode
}

export default function PageWithBanner({
  pageWidth = 1180,
  bannerTitle,
  bannerSubtitle,
  bannerDesktopRightContent,
  bannerBackgroundImage,
  bannerTopRow,
  bannerBottomRow,
  bannerBackgroundColor,
  bannerTabs,
  children,
}: Props) {
  return (
    <>
      <YStack
        borderColor="$hairline"
        borderBottomWidth={1}
        alignItems="center"
        justifyContent="center"
        width="100%"
        backgroundColor={bannerBackgroundColor}
        paddingHorizontal="$3"
      >
        <YStack maxWidth={pageWidth} width="100%">
          {bannerBackgroundImage}
          {bannerTopRow ? (
            <YStack width="100%" paddingTop="$8">
              {bannerTopRow}
            </YStack>
          ) : null}
          <XStack
            gap="$2"
            paddingTop={bannerTopRow ? '$6' : '$10'}
            paddingBottom={bannerBottomRow ? '$6' : '$10'}
            width="100%"
            alignItems="center"
          >
            <YStack flexGrow={1} flexShrink={1}>
              <Heading textTransform="none" size="h1">
                {bannerTitle}
              </Heading>
              {bannerSubtitle ? (
                <BodyText color="secondary" size="editorial">
                  {bannerSubtitle}
                </BodyText>
              ) : null}
            </YStack>
            {bannerDesktopRightContent ? (
              <YStack $mobile={{ display: 'none' }}>{bannerDesktopRightContent}</YStack>
            ) : null}
          </XStack>
          {bannerBottomRow ? (
            <YStack width="100%" paddingBottom="$8">
              {bannerBottomRow}
            </YStack>
          ) : null}
          {bannerTabs}
        </YStack>
      </YStack>
      <YStack
        flexGrow={1}
        flexShrink={1}
        maxWidth={pageWidth}
        width="100%"
        borderRightWidth={1}
        borderLeftWidth={1}
        $desktop={{
          borderRightWidth: 1,
          borderLeftWidth: 1,
          borderColor: '$hairline',
        }}
        style={{ overflowX: 'hidden' }}
        paddingBottom="$8"
      >
        {children}
      </YStack>
    </>
  )
}
